import FlagIcon from '@material-ui/icons/Flag';
import {
  Button,
  Chip,
  Divider,
  Fab,
  FormControlLabel,
  Grid,
  IconButton,
  InputBase,
  Paper,
  Switch,
  TextareaAutosize,
} from '@material-ui/core';
//material ui dialog box
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Select from '@material-ui/core/Select';
import Slide from '@material-ui/core/Slide';
import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import Alert from '@material-ui/lab/Alert';
import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { IoMdClose } from 'react-icons/io';
import { toast } from 'react-toastify';
import CustomHeader from '../../components/Reusable/CustomHeader';
import DebouncedTextField from '../../components/Reusable/DebouncedTextField';
import notification from '../../components/Reusable/Notification';
import SimpleAccordion from '../../components/SimpleAccordion';
import { toastNotification } from '../../utils/eventUtils';
import { uploadFile } from '../../utils/files';
import { get, post, useRequest } from '../../utils/request';
import { generateRoutes } from '../manage-magnets/ManageMagnet';
import MagnetTeam from '../manage-magnets/MagnetTeam';
//import reusable component
import EmailLeads from '../videoembed/EmailLeads';
import EntrataIntegration from '../videoembed/EntrataIntegration';
import Corpus from '../talk-and-triggers/Corpus';
import PhotoUpload from '../talk-and-triggers/PhotoUpload';
import LeadGoalConfiguration from './LeadGoalConfiguration';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
}));

const MagnetSettings = ({ name, community_id, form_id, url, title }) => {
  const [NotificationBar, setNotification] = notification();

  const [openDialog, setOpenDialog] = useState(false);
  const [selectedSetting, setSelectedSetting] = useState();

  const [magnetTemplate, setMagnetTemplate] = useState({});
  const [magnetUuid, setMagnetUuid] = useState('');
  const [magnetType, setMagnetType] = useState('');

  const [startRoute, setStartRoute] = useState('');
  const [gaPropertyId, setGaPropertyId] = useState('');
  const [fbPixelId, setFbPixelId] = useState('');
  const classes = useStyles();

  const emailRef = useRef();
  const [selectedDate, setSelectedDate] = useState(
    new Date('2014-08-18T21:11:54')
  );

  const [magSettings, setMagSettings] = useState({
    location: '',
    features: [],
    communityName: '',
    analyticsApiKey: '',
    website: '',
    phone: '',
    email: '',
    customBranding: {
      img: '',
      url: '',
    },
    backgroundImg: '',
    agents: [], // each agent object in array contains name, email, image
    preferredSettings: '',
  });

  const [dynamicVariableSetting, setDynamicVariableSetting] = useState({
    key: '',
    value: '',
    variables: [],
  });

  // Dynamic Default Embed Settings States
  const [magnetObj, setMagnetObj] = useState('');
  const [screenChoice, setScreenChoice] = useState('');
  const [routeOpts, setRouteOpts] = useState([]);
  const [videoStartChoice, setVideoStartChoice] = useState('');
  const [routeStartChoice, setRouteStartChoice] = useState('');
  const [defaultEmbedFields, setDefaultEmbedFields] = useState({
    layout: '',
    widgetType: '',
    specialOffer: '',
  });
  const [specialOffer, setSpecialOffer] = useState('');

  const chatbotRef = useRef();
  const [chatbot, setchatbot] = useState([
    {
      type: 'Basic',
      text: 'Hi Welcome to the Edge on Blank! How can I help you?',
      buttons: [{ label: '', route: '' }],
    },
  ]);
  console.log('defaultEmbedFieldsrr', defaultEmbedFields);
  const defaultEmbed = {
    buttonLabel: 'Take a virtual tour & $250 off',
    showButton: false,
    showButtonOptions: [{ label: true }, { label: false }],
    startOpen: false,
    startOpenOptions: [{ label: true }, { label: false }],
    primaryColor: '#872322',
    selectedLayout: 'left',
    layouts: [
      { label: 'left' },
      { label: 'center' },
      { label: 'center-bottom' },
      { label: 'right' },
    ],
    selectedTextButton: 'How can I setup account',
    backgroundOpacity: 0.7,
    desktopWidth: 70,
    selectedWidgetType: 'circle',
    widgetTypes: [
      { label: 'circle' },
      { label: 'circle-w-touch' },
      { label: 'vertical' },
      { label: 'vertical-w-touch' },
    ],
  };

  const setTheDynamicVariable = (variables) => {
    console.log('variables', variables);
    const variableobject = {};
    variables.map((item) => {
      variableobject[item.key] = item.value;
    });
    console.log('variableobject', variableobject);
    post(
      '/magnets/template/updateMagnet/dynamic/dynamicvaribales',
      {
        magnet_uuid: magnetUuid,
        variables: variableobject,
      },
      {
        auth: false,
        //, host: "http://localhost:8080"
      }
    )
      .then((response) => {
        // Ensure service worker exists, and that we really are getting a JS file.
        console.log('theDynamicresposne', response);
      })
      .catch(() => {
        console.log(
          'No internet connection found. App is running in offline mode.'
        );
      });
  };

  const setTheDefaultEmbedFields = (default_config) => {
    console.log('_default_config', default_config);

    post(
      '/magnets/template/updateMagnet/dynamic/defaultEmbedupdated',
      {
        magnet_uuid: magnetUuid,
        default_config: {
          ...default_config,
          startOpen: default_config?.startOpen || false,
        },
      },
      {
        auth: false,
        //, host: "http://localhost:8080"
      }
    )
      .then((response) => {
        // Ensure service worker exists, and that we really are getting a JS file.
        // getDefaultEmbedFields(magnetUuid);
        console.log('thedefaultresposne', response);
        if (response.default_config) {
          setDefaultEmbedFields({
            ...defaultEmbedFields,
            ...response.default_config,
          });
        }
        setNotification(`Settings updated`, 'success');
      })
      .catch(() => {
        console.log(
          'No internet connection found. App is running in offline mode.'
        );
      });
  };

  const getDynamicVariable = async (magnet_Uuid) => {
    get(
      `/magnets/template/updateMagnet/dynamic/dynamicvaribales?magnet_uuid=${magnet_Uuid}`,
      {
        auth: false,
        //, host: "http://localhost:8080"
      }
    )
      .then((response) => {
        // Ensure service worker exists, and that we really are getting a JS file.
        console.log('thedyanmicresposne', response.dynamic);
        let mod_response = [];
        Object.keys(response.dynamic).map((item) => {
          mod_response.push({
            key: item,
            value: response?.dynamic[item],
          });
        });
        console.log('mod_response', mod_response);
        if (response.dynamic) {
          setDynamicVariableSetting({ variables: mod_response });
        }
        console.log('dynamicVariableSetting', dynamicVariableSetting);
      })
      .catch(() => {
        console.log(
          'No internet connection found. App is running in offline mode.'
        );
      });
  };

  const getDefaultEmbedFields = async (magnet_Uuid) => {
    get(
      `/magnets/template/updateMagnet/dynamic/defaultEmbed?magnet_uuid=${magnet_Uuid}`,
      {
        auth: false,
        //, host: "http://localhost:8080"
      }
    )
      .then((response) => {
        // Ensure service worker exists, and that we really are getting a JS file.
        console.log('theresposne', response);
        if (response.default_config) {
          if (response?.default_config?.chatbot) {
            setchatbot(response?.default_config?.chatbot);
          }
          if (response.default_config.startOpen == '') {
            response.default_config.startOpen = false;
          }
          setDefaultEmbedFields({
            //...defaultEmbedFields,
            ...response.default_config,
          });
          setScreenChoice(response.default_config.screenChoice);
        }
      })
      .catch(() => {
        console.log(
          'No internet connection found. App is running in offline mode.'
        );
      });
  };

  // COMMUNITY FEATURE FUNCTIONS
  function addFeature() {
    let currentFeatures = magSettings.features;
    currentFeatures.push('');
    setMagSettings({ ...magSettings, features: currentFeatures });
  }

  function deleteFeature(idxToDelete) {
    let currentFeatures = magSettings.features;
    currentFeatures.splice(idxToDelete, 1);
    setMagSettings({ ...magSettings, features: currentFeatures });
  }

  function updateFeature(idxToUpdate, value) {
    let currentFeatures = magSettings.features;
    currentFeatures[idxToUpdate] = value;
    setMagSettings({ ...magSettings, features: currentFeatures });
  }

  // Dynamic Variables Funcations
  const addVariable = () => {
    let currentVariable = dynamicVariableSetting.variables;
    currentVariable.push({ key: '', value: '' });
    setDynamicVariableSetting({
      ...dynamicVariableSetting,
      variables: currentVariable,
    });
  };

  const updateVariable = (idxToUpdate, e) => {
    let currentVariable = dynamicVariableSetting.variables;
    currentVariable[idxToUpdate] = {
      key: e.target.value,
      value: currentVariable[idxToUpdate].value,
    };
    setDynamicVariableSetting({
      ...dynamicVariableSetting,
      variables: currentVariable,
    });

    console.log('current variable....', idxToUpdate);

    //setTheDynamicVariable(dynamicVariableSetting.variables)
  };

  const setDynamicVariableval = (idxToUpdate, e) => {
    let currentVariable = dynamicVariableSetting.variables;
    currentVariable[idxToUpdate] = {
      key: currentVariable[idxToUpdate].key,
      value: e.target.value,
    };
    setDynamicVariableSetting({
      ...dynamicVariableSetting,
      variables: currentVariable,
    });

    //setTheDynamicVariable(dynamicVariableSetting.variables)
  };

  // Add Bubble Row
  const addBubbleRow = (index) => {
    let currentBubble = chatbot;
    currentBubble[index].buttons.push({ label: '', route: '' });
    setchatbot([...currentBubble]);
  };

  const editBubbleRowValues = (bubbleIndex, buttonIndex, e) => {
    var urlRegexExp = /(^(http|https):\/\/)/gi;
    var urlReg = new RegExp(urlRegexExp);

    let currentBubble = chatbot;
    if (e.target.name === 'message') {
      currentBubble[bubbleIndex].text = e.target.value;
    } else if (e.target.name === 'button-name') {
      currentBubble[bubbleIndex].buttons[buttonIndex] = {
        label: e.target.value,
        route: currentBubble[bubbleIndex].buttons[buttonIndex].route,
      };
    } else if (e.target.name === 'path') {
      if (e.target.value.match(urlReg)) {
        currentBubble[bubbleIndex].buttons[buttonIndex] = {
          label: currentBubble[bubbleIndex].buttons[buttonIndex].label,
          href: e.target.value,
        };
      } else {
        currentBubble[bubbleIndex].buttons[buttonIndex] = {
          label: currentBubble[bubbleIndex].buttons[buttonIndex].label,
          route: e.target.value,
        };
      }
    } else {
      return null;
    }
    setchatbot([...currentBubble]);
  };

  // Delete Bubble Row
  const deleteBubbleRow = (bubbleIndex, buttonIndex) => {
    console.log('bubbleIndex', bubbleIndex, 'buttonIndex', buttonIndex);
    let currentBubble = [...chatbot];
    currentBubble[bubbleIndex].buttons.splice(buttonIndex, 1);
    console.log('currentBubble', currentBubble);
    setchatbot([...currentBubble]);
  };

  const addNewChatBubble = () => {
    setchatbot([
      ...chatbot,
      {
        type: 'Basic',
        text: 'Hi Welcome to the Edge on Blank! How can I help you?',
        buttons: [{ label: '', route: '', href: '' }],
      },
    ]);
  };

  const deleteChatBubble = (idxToDelete) => {
    let currentchatbot = chatbot;
    currentchatbot.splice(idxToDelete, 1);
    setchatbot([...currentchatbot]);
  };

  const deleteVariable = (idxToDelete) => {
    console.log('idxToDelete', idxToDelete);
    let currentVariable = dynamicVariableSetting.variables;
    console.log('currentVariable', currentVariable);
    currentVariable.splice(idxToDelete, 1);
    setDynamicVariableSetting({
      ...dynamicVariableSetting.variables,
      variables: currentVariable,
    });
  };
  function handleDateChange(date) {
    setSelectedDate(date);
    // setEmails({ ...emails, date_time: date.toISOString() });
  }
  // LEASING AGENT FUNCTIONS
  function addAgent() {
    let currentAgents = magSettings.agents;
    // Create new blank agent
    const agent = {
      name: '',
      email: '',
      image: '',
    };
    currentAgents.push(agent);
    setMagSettings({ ...magSettings, agents: currentAgents });
  }

  // const [loading, error, data, makeRequest] = useRequest({ loading: true });
  const getMagnet = async () => {
    const res = await makeRequest(
      `/magnets?queryByCommunity=${community_id}`,
      'GET',
      null,
      { auth: false }
    );
    console.log('my result : ', res);

    let _magnetObj = res.magnets[0]?.magnet_details?.template;
    setMagnetObj(_magnetObj);
    setStartRoute(_magnetObj.start_route);
    setGaPropertyId(_magnetObj.gaPropertyId);
    setFbPixelId(_magnetObj.fbPixelId);
    setMagnetUuid(res.magnets[0].uuid);
    setMagnetType(res.magnets[0].magnet_type);
    setMagnetTemplate(res.magnets[0].magnet_details.template);
    setPhotoUrl(
      res.magnets[0]?.magnet_details?.template?.magnetSettings?.customBranding
        ?.img
    );
    setmagnetSettings(res.magnets[0]?.magnet_details?.template?.magnetSettings);
    setWebsiteUrl(
      res.magnets[0]?.magnet_details?.template?.magnetSettings?.customBranding
        ?.url
    );
    // get variables
    getDynamicVariable(res.magnets[0].uuid);
    getDefaultEmbedFields(res.magnets[0].uuid);

    let tempDict = {};

    if (_magnetObj && 'magnetSettings' in _magnetObj) {
      let magSettingKeys = Object.keys(magSettings);

      for (let k of magSettingKeys) {
        if (
          _magnetObj.magnetSettings &&
          Object.keys(_magnetObj.magnetSettings).includes(k)
        ) {
          tempDict[k] = _magnetObj.magnetSettings[k];
        } else {
          tempDict[k] = magSettings[k];
        }
      }
      setMagSettings(tempDict);
    }
    console.log('new magnet settings: ', tempDict);
  };

  function updateSettings() {
    let _defaultEmbed = {
      showButton: defaultEmbedFields.showButton,
      startOpen: defaultEmbedFields.startOpen,
      layout: defaultEmbedFields.layout,
      primaryColor: defaultEmbedFields.primaryColor,
      startScreen: routeStartChoice,
      screenChoice: screenChoice,
      buttonLabel: defaultEmbedFields.buttonLabel,
      backgroundOpacity: defaultEmbedFields.backgroundOpacity,
      desktopWidth: defaultEmbedFields.desktopWidth,
      widgetType: defaultEmbedFields.widgetType,
      chatbot: defaultEmbedFields.chatbot,
      goals: defaultEmbedFields.goals,
      uuid: magnetUuid,
      blockEvents: 'keydown',
    };
    console.log('saving defaultConfig', _defaultEmbed);

    // Update Default Embed Setting
    setTheDefaultEmbedFields(_defaultEmbed, chatbot);

    // update Dynamic Variables

    // Update Default Embed Setting
    // post(`/updateMagnet/dynamic/defaultEmbedupdated?magnetUuid=${magnetUuid}`, {
    //   magnet_uuid: magnetUuid,
    //   magnetSettings: magSettings,
    //   start_route: startRoute,
    //   gaPropertyId: gaPropertyId,
    //   fbPixelId: fbPixelId,
    //   magnet_type: magnetType,
    // })
    //   .then((data) => {
    //     console.log('successfully update default embed form');
    //   })
    //   .catch((error) => {
    //     console.log('Error:', error.message);
    //   });

    post('/magnets/template/settings', {
      magnet_uuid: magnetUuid,
      magnetSettings: magSettings,
      start_route: startRoute,
      gaPropertyId: gaPropertyId,
      fbPixelId: fbPixelId,
      magnet_type: magnetType,
    }).then((data) => {
      toastNotification('Settings updated', 'success');
    });

    console.log('just posted the magnet', {
      magnet_uuid: magnetUuid,
      template: {
        ...magnetTemplate,
        magnetSettings: magSettings,
        start_route: startRoute,
        gaPropertyId: gaPropertyId,
        fbPixelId: fbPixelId,
      },
      magnet_type: magnetType,
    });
  }

  useEffect(() => {
    getMagnet();
  }, [community_id]);

  useEffect(() => {
    if (magnetObj) {
      const tempRoutes = generateRoutes(magnetObj);
      setRouteOpts(tempRoutes);
      setScreenChoice(
        tempRoutes.length > 0
          ? tempRoutes[0].route + '|' + tempRoutes[0].video
          : ''
      );
      setVideoStartChoice(tempRoutes.length > 0 ? tempRoutes[0].video : '');
      setRouteStartChoice(tempRoutes.length > 0 ? tempRoutes[0].route : '');
    }
  }, [magnetObj]);

  const handleChangeMenu = (event) => {
    console.log(event.target.value);
    setScreenChoice(event.target.value);
    if (event.target.value.includes('|')) {
      const route = event.target.value.split('|', 2)[0];
      const videourl = event.target.value.split('|', 2)[1];
      console.log('route: ', route);
      setRouteStartChoice(route);
      setVideoStartChoice(videourl);
    }
  };

  const handleClose = () => {
    setOpenDialog(false);
    setSelectedSetting('');
  };

  const [loading, error, data, makeRequest] = useRequest({ loading: false });
  const [leadError, setLeadError] = useState();
  const [emailError, setEmailError] = useState();
  const [currEmail, setCurrEmail] = useState();
  const [facebookPixel, setFacebookPixel] = useState('');
  const [googleAnalyticsVal, setGoogleAnalyticsVal] = useState('');

  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState('');
  const [photoUrl, setPhotoUrl] = useState('');
  const [magnetSettings, setmagnetSettings] = useState('');
  const [progress, setProgress] = useState(null);
  const [websiteUrl, setWebsiteUrl] = useState('');

  const [uuid, setUuid] = useState();

  const [plainEmail, setPlainEmail] = useState({
    team_email: '',
    live: false,
    template: `
    Email: {{leadEmail}}
    Name: {{leadName}}
    First Name: {{leadFirstName}}
    Last Name: {{leadLastName}}
    Phone: {{leadPhone}}
    Notes: {{leadNotes}}
    `,
    subject: 'New Lead from LeaseMagnets - {{leadName}}',
  });

  const [weeklyReportingEmails, setWeeklyReportingEmails] = useState({
    team_email: '',
    live: false,
  });

  const [customizableEmail, setCustomizableEmail] = useState({
    emailType: 'promo',
    subject: '',
    template: '',
    cmpEmail: '',
    live: false,
  });

  const [newLeadEmail, setNewLeadEmail] = useState({
    team_email: '',
    live: false,
  });

  const weekdays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

  useEffect(() => {
    if (!form_id) {
      makeRequest(`/magnets?queryByCommunity=${community_id}`).then((res) => {
        setUuid(res?.magnets[0]?.uuid);
        console.log('the restulstss are ', res);
        getAllIntegrations(res.magnets[0].uuid);
      });
    } else {
      getAllIntegrations(form_id);
    }
  }, [community_id]);

  async function getAllIntegrations(form_id) {
    const res = await get(`/integration/details?magnet_uuid=${form_id}`);
    console.log('resss', res);
    if (res.status !== 'fail') {
      console.log('integrations json = ', res);
      if (res['email-team']) {
        setNewLeadEmail(res['email-team']);
      }
      if (res['email-lead-promo']) {
        setCustomizableEmail(res['email-lead-promo']);
      }
      if (res['facebook-pixel']) {
        setFacebookPixel(res['facebook-pixel']);
      }
      if (res['google-analytics']) {
        setGoogleAnalyticsVal(res['google-analytics']);
      }
      if (res['email-plain']) {
        setPlainEmail({ ...plainEmail, ...res['email-plain'] });
      }
      if (res['weekly-reporting']) {
        if (typeof res['weekly-reporting'] != 'string') {
          setWeeklyReportingEmails({
            ...res['weekly-reporting'],
            weekday: res['weekly-reporting']?.weekday
              ? res['weekly-reporting']?.weekday
              : 'Sun',
          });
        }
      }
    }
  }

  const plainEmailRef = useRef();

  function handleSubmitPlainEmail(email = currEmail) {
    const re = /^[^\s@]+@[^\s@]+$/;
    if (plainEmail.team_email?.includes(email.toLowerCase())) {
      setEmailError('Email already entered');
    } else if (re.test(email)) {
      setEmailError();
      let temp = plainEmail?.team_email?.split(',').filter((o) => o);
      temp.push(email?.toLowerCase());
      console.log('temp', temp);
      setPlainEmail({
        ...plainEmail,
        team_email: temp.join(','),
      });
      plainEmailRef.current.value = '';
    } else {
      setEmailError('Please enter a valid email address');
    }
  }

  function handleSubmitEmail(email = currEmail) {
    const re = /^[^\s@]+@[^\s@]+$/;
    if (weeklyReportingEmails.team_email?.includes(email?.toLowerCase())) {
      setEmailError('Email already entered');
    } else if (re.test(email)) {
      setEmailError();
      console.log('weeklyReportingEmails', weeklyReportingEmails);
      let temp = [];
      if (weeklyReportingEmails?.team_email) {
        temp = weeklyReportingEmails?.team_email?.split(',').filter((o) => o);
      }
      temp.push(email?.toLowerCase());
      setWeeklyReportingEmails({
        ...weeklyReportingEmails,
        team_email: temp?.join(','),
      });
      setCurrEmail('');
      emailRef.current.value = '';
    } else {
      setEmailError('Please enter a valid email address');
    }
  }

  const defaultTourSetting = () => {
    return (
      <SettingDialog
        title="Dynamic Default Embed Settings"
        open={openDialog}
        onClose={handleClose}
        handleClose={handleClose}
        setOpenDialog={setOpenDialog}
        customButtonText="Save"
        customButtonFunction={async () => {
          updateSettings();
        }}
      >
        <div className="default-emded-setting flex flex-col">
          <div className="wrap">
            <div className="form flex flex-col">
              <div className="feild flex flex-col">
                <div className="lbl font s14 b4 c000">Button Label</div>
                <div className="itm flex aic">
                  <input
                    className="cleanbtn iput font s15 b4 c000"
                    value={defaultEmbedFields.buttonLabel}
                    placeholder={defaultEmbed.buttonLabel}
                    onChange={(e) => {
                      setDefaultEmbedFields({
                        ...defaultEmbedFields,
                        buttonLabel: e.target.value,
                      });
                    }}
                  />
                </div>
              </div>
              <div className="feild flex flex-col">
                <div className="lbl font s14 b4 c000">Primary Color</div>
                <div className="itm flex aic">
                  <input
                    className="cleanbtn iput font s15 b4 c000"
                    value={defaultEmbedFields.primaryColor}
                    placeholder={defaultEmbed.primaryColor}
                    onChange={(e) => {
                      setDefaultEmbedFields({
                        ...defaultEmbedFields,
                        primaryColor: e.target.value,
                      });
                    }}
                  />
                </div>
              </div>
              <div className="feild flex flex-col">
                <div className="lbl font s14 b4 c000">Show Button</div>
                <Select
                  labelId="select screen"
                  id="select screen"
                  value={defaultEmbedFields.showButton}
                  onChange={(event) => {
                    setDefaultEmbedFields({
                      ...defaultEmbedFields,
                      showButton: event.target.value,
                    });
                  }}
                  disableUnderline
                  style={{
                    border: '1px solid #ddd',
                    padding: '8px 15px 5px 15px',
                    borderRadius: '4px',
                  }}
                >
                  {defaultEmbed.showButtonOptions.map((item, index) => (
                    <MenuItem
                      key={index}
                      value={item.label}
                      style={{ backgroundColor: '#fff' }}
                    >
                      {item.label.toString()}
                    </MenuItem>
                  ))}
                </Select>
              </div>
              <div className="feild flex flex-col">
                <div className="lbl font s14 b4 c000">Start Open</div>
                <Select
                  labelId="select screen"
                  id="select screen"
                  value={defaultEmbedFields?.startOpen}
                  defaultValue={false}
                  onChange={(event) => {
                    setDefaultEmbedFields({
                      ...defaultEmbedFields,
                      startOpen: event.target.value,
                    });
                  }}
                  disableUnderline
                  style={{
                    border: '1px solid #ddd',
                    padding: '8px 15px 5px 15px',
                    borderRadius: '4px',
                  }}
                >
                  {defaultEmbed.startOpenOptions.map((item, index) => (
                    <MenuItem
                      key={index}
                      value={item.label}
                      style={{ backgroundColor: '#fff' }}
                    >
                      {item.label.toString()}
                    </MenuItem>
                  ))}
                </Select>
              </div>
              <div className="feild flex flex-col">
                <div className="lbl font s14 b4 c000">Layout</div>
                <Select
                  labelId="select screen"
                  id="select screen"
                  value={defaultEmbedFields.layout}
                  onChange={(event) => {
                    setDefaultEmbedFields({
                      ...defaultEmbedFields,
                      layout: event.target.value,
                    });
                  }}
                  disableUnderline
                  style={{
                    border: '1px solid #ddd',
                    padding: '8px 15px 5px 15px',
                    borderRadius: '4px',
                  }}
                >
                  {defaultEmbed.layouts.map((item, index) => (
                    <MenuItem
                      key={index}
                      value={item.label}
                      style={{ backgroundColor: '#fff' }}
                    >
                      {item.label}
                    </MenuItem>
                  ))}
                </Select>
              </div>
              <div className="feild flex flex-col">
                <div className="lbl font s14 b4 c000">Start Screen</div>
                <Select
                  labelId="select screen"
                  id="select screen"
                  value={screenChoice} // If at least one routeOpts exist, choose the first, else return empty
                  onChange={handleChangeMenu}
                  disableUnderline
                  style={{
                    border: '1px solid #ddd',
                    padding: '8px 15px 5px 15px',
                    borderRadius: '4px',
                  }}
                >
                  {routeOpts.map(({ id, route, video }) => (
                    <MenuItem
                      key={id + route + video}
                      value={route + '|' + video}
                      style={{ backgroundColor: '#fff' }}
                    >
                      {id}
                    </MenuItem>
                  ))}
                </Select>
              </div>
              <div className="feild flex flex-col">
                <div className="lbl font s14 b4 c000">Background Opacity</div>
                <div className="itm flex aic">
                  <input
                    className="cleanbtn iput font s15 b4 c000"
                    value={defaultEmbedFields.backgroundOpacity}
                    placeholder={defaultEmbed.backgroundOpacity}
                    onChange={(e) => {
                      setDefaultEmbedFields({
                        ...defaultEmbedFields,
                        backgroundOpacity: e.target.value,
                      });
                    }}
                  />
                </div>
              </div>
              <div className="feild flex flex-col">
                <div className="lbl font s14 b4 c000">Desktop Width</div>
                <div className="itm flex aic">
                  <input
                    className="cleanbtn iput font s15 b4 c000"
                    value={defaultEmbedFields.desktopWidth}
                    placeholder={defaultEmbed.desktopWidth}
                    onChange={(e) => {
                      setDefaultEmbedFields({
                        ...defaultEmbedFields,
                        desktopWidth: e.target.value,
                      });
                    }}
                  />
                </div>
              </div>
              <div className="feild flex flex-col">
                <div className="lbl font s14 b4 c000">Widget Type</div>
                <Select
                  labelId="select screen"
                  id="select screen"
                  value={defaultEmbedFields.widgetType}
                  onChange={(event) => {
                    setDefaultEmbedFields({
                      ...defaultEmbedFields,
                      widgetType: event.target.value,
                    });
                  }}
                  disableUnderline
                  style={{
                    border: '1px solid #ddd',
                    padding: '8px 15px 5px 15px',
                    borderRadius: '4px',
                  }}
                >
                  {defaultEmbed.widgetTypes.map((item, index) => (
                    <MenuItem
                      key={index}
                      value={item.label}
                      style={{ backgroundColor: '#fff' }}
                    >
                      {item.label}
                    </MenuItem>
                  ))}
                </Select>
              </div>
            </div>
          </div>
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              disabled
              onClick={() => {
                // disabled for now
                // setTheDefaultEmbedFields({});
                // setDefaultEmbedFields({
                //   buttonLabel: '',
                //   showButton: '',
                //   showButtonOptions: '',
                //   startOpen: '',
                //   startOpenOptions: '',
                //   primaryColor: '',
                //   selectedLayout: '',
                //   layouts: '',
                //   selectedTextButton: '',
                //   backgroundOpacity: '',
                //   desktopWidth: '',
                //   selectedWidgetType: '',
                //   widgetTypes: '',
                // });
              }}
            >
              Clear default Config
            </Button>
            <NotificationBar />
          </Grid>
        </div>
      </SettingDialog>
    );
  };
  const aiCorpus = () => {
    return (
      <SettingDialog
        title="Ai FAQ Corpus"
        open={openDialog}
        onClose={handleClose}
        handleClose={handleClose}
        setOpenDialog={setOpenDialog}
        maxWidth="lg"
      >
        <div className="default-emded-setting flex flex-col">
          <Corpus community_id={community_id} magnetUuid={magnetUuid} />
        </div>
      </SettingDialog>
    );
  };
  const photoGallery = () => {
    return (
      <SettingDialog
        title="Photo Gallery "
        open={openDialog}
        onClose={handleClose}
        handleClose={handleClose}
        setOpenDialog={setOpenDialog}
        maxWidth="lg"
      >
        <div className="default-emded-setting flex flex-col">
          <PhotoUpload community_id={community_id} magnetUuid={magnetUuid} />
        </div>
      </SettingDialog>
    );
  };

  const communitymanagers = () => {
    return (
      <SettingDialog
        title="Community managers "
        open={openDialog}
        onClose={handleClose}
        handleClose={handleClose}
        setOpenDialog={setOpenDialog}
        maxWidth="lg"
      >
        <div className="default-emded-setting flex flex-col">
          <MagnetTeam
            name={
              magSettings?.communityName ? magSettings?.communityName : null
            }
            url={''}
            community_id={community_id}
            title="Team"
          />
        </div>
      </SettingDialog>
    );
  };

  const dynamicVariablesSetting = () => {
    return (
      <SettingDialog
        title="Dynamic Variables"
        open={openDialog}
        onClose={handleClose}
        handleClose={handleClose}
        setOpenDialog={setOpenDialog}
        customButtonText="Save"
        customButtonFunction={async () => {
          updateSettings();
        }}
      >
        <div className="dynamic-variables-setting flex flex-col">
          <div className="sub-txt font s14 c333">
            Make it easy to pass in custom variable into the tourmagnet
          </div>
          <div className="variables-wrapper">
            {dynamicVariableSetting.variables.length > 0 && (
              <div className="hdr flex aic">
                <div className="col-one">
                  <div className="label font s14 b6 c333">Key</div>
                </div>
                <div className="col-two">
                  <div className="label font s14 b6 c333">Value</div>
                </div>
              </div>
            )}

            {dynamicVariableSetting.variables.map((item, idx) => (
              <div key={idx} className="block flex aic">
                <div className="col-one">
                  <div className="field flex flex-col">
                    <input
                      type="text"
                      placeholder="key"
                      className="iput font s14 cleanbtn"
                      value={item.key}
                      onChange={(e) => {
                        updateVariable(idx, e);
                        // dynamicVariableSetting.variables[idx] = {
                        //   ...dynamicVariableSetting.variables[idx],
                        //   key: e.target.value,
                        // };
                      }}
                    />
                  </div>
                </div>
                <div className="col-two">
                  <div className="field flex flex-col">
                    <input
                      type="text"
                      placeholder="value"
                      className="iput font s14 cleanbtn"
                      value={item.value}
                      onChange={(e) => {
                        //updateVariable(idx, e);
                        setDynamicVariableval(idx, e);
                        // dynamicVariableSetting.variables[idx] = {
                        //   ...dynamicVariableSetting.variables[idx],
                        //   value: e.target.value,
                        // };
                      }}
                    />
                  </div>
                </div>
                <div className="col-three">
                  <button
                    onClick={() => deleteVariable(idx)}
                    className="cleanbtn del-btn icon-trash c333 s20"
                  />
                </div>
              </div>
            ))}
          </div>
          <div className="ftr flex aic">
            <button
              className="cleanbtn add-btn font s14 b4 c333"
              onClick={addVariable}
            >
              Add New Variable
            </button>
            <button
              className="cleanbtn save-btn font s14 b4 cfff"
              //onChange={(newValue) => updateVariable(idx, newValue)}
              onClick={() => {
                setTheDynamicVariable(dynamicVariableSetting.variables);
              }}
            >
              Save Change
            </button>
          </div>
        </div>
      </SettingDialog>
    );
  };

  const onClickLink = (link) => {
    if (link.route) {
      const newRoute = link.route.split('.');
      // onClick(newRoute);
    } else if (link.href) {
      // This creates an inconsistency in the "linked list" of Events, which
      // each have a "to" and a "from" route. We could fire another Event here
      // from `link.href` to `currentRoute`, or when processing Events, use the
      // previous `from` if previous `to` is not equal to current `from`
      window.open(link.href, '_blank').focus();
    } else if (link.href_direct) {
      // This creates an inconsistency in the "linked list" of Events, which
      // each have a "to" and a "from" route. We could fire another Event here
      // from `link.href` to `currentRoute`, or when processing Events, use the
      // previous `from` if previous `to` is not equal to current `from`
      window.open(link.href_direct, '_self').focus();
    }
  };

  function copy(e, ref) {
    setChatBotCopied(true);
    ref.current.select();
    document.execCommand('copy');
    e.target.focus();
    onCopyText();
  }

  const onCopyText = () => {
    setTimeout(() => {
      setChatBotCopied(false);
    }, 1000);
  };

  const [chatBotCopied, setChatBotCopied] = useState(false);
  const chatbotSetting = () => {
    return (
      <SettingDialog
        title="Chatbot Defaults"
        open={openDialog}
        onClose={handleClose}
        maxWidth={'md'}
        handleClose={handleClose}
        setOpenDialog={setOpenDialog}
        customButtonText="Save"
        customButtonFunction={async () => {
          updateSettings(chatbot);
        }}
      >
        <div className="chatbot-setting-dialog flex">
          <div className="ls flex flex-col">
            <div className="bubbles-list">
              {chatbot.length &&
                chatbot.map((bubble, index) => (
                  <div key={index} className="bubble flex flex-col">
                    <div className="bubble-hdr">
                      <button
                        onClick={deleteChatBubble}
                        className="hdr-btn font"
                      >
                        <span className="ico icon-delete1 b6 s16"></span>
                        <span>Delete</span>
                      </button>
                    </div>

                    <div className="bubble-content">
                      <div className="bubble-content-hdr ">
                        <div className="meta">
                          <div className="title font s15 b6 black">{`Type: ${bubble.type}`}</div>
                          <input
                            type="text"
                            name="message"
                            className="disc-input font"
                            placeholder="Type Message..."
                            defaultValue={bubble.text}
                            onChange={(e) => {
                              editBubbleRowValues(index, '', e);
                            }}
                          />
                        </div>
                        <div className="rit">
                          <button
                            onClick={() => addBubbleRow(index)}
                            className="hdr-btn font"
                          >
                            <span className="ico icon-plus b6 s15"></span>
                            <span>Add new</span>
                          </button>
                        </div>
                      </div>
                      <div className="variables-wrapper">
                        {bubble.buttons.map((item, idx) => (
                          <div key={idx} className="block flex aic">
                            <div className="block-col">
                              <button className="drag-btn icon-apps4 black" />
                            </div>
                            <div className="block-col">
                              <div className="field flex flex-col">
                                <input
                                  type="text"
                                  name="button-name"
                                  placeholder="Button name"
                                  className="iput font s14 cleanbtn"
                                  defaultValue={item.label}
                                  value={item.label}
                                  onChange={(e) => {
                                    editBubbleRowValues(index, idx, e);
                                  }}
                                />
                              </div>
                            </div>
                            <div
                              className="block-col  min-w-full"
                              style={{ minWidth: '200px' }}
                            >
                              <div className="field flex flex-col ">
                                <input
                                  type="text"
                                  name="path"
                                  placeholder="Route"
                                  className="iput font s14 cleanbtn"
                                  defaultValue={item?.route || item?.href}
                                  value={item?.route || item?.href}
                                  onChange={(e) => {
                                    editBubbleRowValues(index, idx, e);
                                  }}
                                />
                              </div>
                            </div>
                            <div className="block-col">
                              {/* <button
                              onClick={() => deleteVariable(idx)}
                              className="cleanbtn del-btn icon-create c333 s20"
                          /> */}
                              <button
                                onClick={() => deleteBubbleRow(index, idx)}
                                className="cleanbtn del-btn icon-delete1 lightBlack s20"
                              />
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                ))}
            </div>
            <div className="flex aic">
              <button
                onClick={addNewChatBubble}
                className="add-bubble-btn font s15 b5 flex aic"
              >
                <span className="ico icon-plus s15"></span>
                <span>Add bubble</span>
              </button>
            </div>
          </div>
          specialOffer
          <div className="rs flex flex">
            <div className="chatbot-preview">
              <div className="preview-hdr flex aic">
                <div className="font tit black s14">Chatbot Public Preview</div>
              </div>
              <div className="chatbot-bubbles">
                {chatbot?.length > 0 &&
                  chatbot?.map((item, index) => {
                    return (
                      <div
                        key={index}
                        className={`initial-message-bubble ${
                          item.buttons.length === 1 && !item.text
                            ? 'transparent'
                            : ''
                        }`}
                      >
                        {item.title && (
                          <div className="message-title">{item.title}</div>
                        )}
                        {item.text && (
                          <div className="message-text">{item.text}</div>
                        )}
                        {item.users && (
                          <div className="support-block">
                            {item.users.map((user, index) => (
                              <div className="user">
                                <div
                                  className="image"
                                  style={{
                                    backgroundImage: `url(${user.img})`,
                                  }}
                                />
                                <div className="name">{user.name}</div>
                              </div>
                            ))}
                          </div>
                        )}
                        {item.buttons.length > 0 && (
                          <div className="bubble-buttons">
                            {item.buttons.map((button) => (
                              <button
                                onClick={() => onClickLink(button)}
                                className={`bubble-btn ${
                                  button.fullWidth ? 'full' : ''
                                }`}
                              >
                                {button.label}
                              </button>
                            ))}
                          </div>
                        )}
                      </div>
                    );
                  })}
              </div>
            </div>

            <textarea
              ref={chatbotRef}
              className="copy-snippet"
              readOnly
              value={`<script src="https://embed.tour.video/leasemagnets.js"></script> <script>LeaseMagnets({ uuid: "${magnetUuid}", layout: "left", primaryColor: "#F26522", startScreen: "founders.bloom", buttonLabel: "", desktopWidth: 40, chatbot: ${JSON.stringify(
                chatbot
              )} })</script>`}
            />
            <button
              className="copy-btn rel"
              onClick={(e) => copy(e, chatbotRef)}
            >
              Copy chatbot code
              <div
                className={`copied font s14 b4 cfff anim ${
                  chatBotCopied ? 'sho' : 'hid'
                }`}
              >
                Copied!
              </div>
            </button>
          </div>
        </div>
      </SettingDialog>
    );
  };

  const SpecialOffer = () => {
    return (
      <SettingDialog
        title="Special Offer"
        open={openDialog}
        onClose={handleClose}
        handleClose={handleClose}
        setOpenDialog={setOpenDialog}
        customButtonText="Save"
        customButtonFunction={async () => {
          updateSettings();
        }}
      >
        <div className="special-offer-dialog flex flex-col">
          <div className="field">
            <input
              type="text"
              placeholder="What promotion / special offer would you like to run?"
              className="iput font"
              value={defaultEmbedFields?.specialOffer || ''}
              onChange={(e) =>
                setDefaultEmbedFields({
                  ...defaultEmbedFields,
                  specialOffer: e.target.value,
                })
              }
            />
            <span>
              This will be accessible by the route: "_promotions_.latest" or by
              typing "Special Offer [Latest]" into any button input field.
              Clearing the special will remove any specifics from the tour!
            </span>
          </div>
        </div>
      </SettingDialog>
    );
  };

  const sharePageSettings = () => {
    return (
      <SettingDialog
        title="Community Settings"
        open={openDialog}
        onClose={handleClose}
        handleClose={handleClose}
        setOpenDialog={setOpenDialog}
        customButtonText="Save"
        customButtonFunction={async () => {
          updateSettings();
        }}
      >
        <div className="share-page-settings">
          <div className="section flex flex-col">
            <div className="title font s15 b6 black">Community Settings</div>
            <div className="wrap flex flex-col">
              <DebouncedTextField
                label="Community Name"
                value={magSettings.communityName}
                onChange={(newValue) =>
                  setMagSettings({ ...magSettings, communityName: newValue })
                }
                fullWidth
              />
              <DebouncedTextField
                label="Community Location"
                value={magSettings.location}
                onChange={(newValue) =>
                  setMagSettings({ ...magSettings, location: newValue })
                }
                fullWidth
              />
              <DebouncedTextField
                label="Community Website"
                value={magSettings.website}
                onChange={(newValue) =>
                  setMagSettings({ ...magSettings, website: newValue })
                }
                fullWidth
              />
              <DebouncedTextField
                label="Community Contact Phone Number"
                value={magSettings.phone}
                onChange={(newValue) =>
                  setMagSettings({ ...magSettings, phone: newValue })
                }
                fullWidth
              />
              <DebouncedTextField
                label="Community Contact Email"
                value={magSettings.email}
                onChange={(newValue) =>
                  setMagSettings({ ...magSettings, email: newValue })
                }
                fullWidth
              />
              <DebouncedTextField
                label="Custom Branding Image URL"
                value={magSettings.customBranding.img}
                onChange={(newValue) =>
                  setMagSettings({
                    ...magSettings,
                    customBranding: {
                      ...magSettings.customBranding,
                      img: newValue,
                    },
                  })
                }
                fullWidth
              />
              <DebouncedTextField
                label="Community Background Image"
                value={magSettings.backgroundImg}
                onChange={(newValue) =>
                  setMagSettings({ ...magSettings, backgroundImg: newValue })
                }
                fullWidth
              />
              <DebouncedTextField
                label="Custom Branding Redirect URL"
                value={magSettings.customBranding.url}
                onChange={(newValue) =>
                  setMagSettings({
                    ...magSettings,
                    customBranding: {
                      ...magSettings.customBranding,
                      url: newValue,
                    },
                  })
                }
                fullWidth
              />
              <DebouncedTextField
                label="Community Background Image"
                value={magSettings.backgroundImg}
                onChange={(newValue) =>
                  setMagSettings({ ...magSettings, backgroundImg: newValue })
                }
                fullWidth
              />
            </div>
          </div>
          <div className="section flex flex-col">
            <div className="title font s15 b6 black">
              Your Preferred Feature
            </div>
            <div className="wrap flex flex-col">
              <FormControl component="fieldset">
                <RadioGroup
                  row
                  aria-label="position"
                  name="position"
                  value={magSettings.preferredSettings}
                  onChange={(e) =>
                    setMagSettings({
                      ...magSettings,
                      preferredSettings: e.target.value,
                    })
                  }
                >
                  <FormControlLabel
                    value="Widget Settings"
                    control={<Radio color="primary" />}
                    label="Widget Settings"
                  />
                  <FormControlLabel
                    value="Tour Page"
                    control={<Radio color="primary" />}
                    label="Tour Page"
                  />
                </RadioGroup>
              </FormControl>
            </div>
          </div>
          <div className="section flex flex-col">
            <div className="title font s15 b6 black features-title">
              Basic Community Features
            </div>
            <div className="wrap flex flex-col">
              {magSettings.features.map((feature, feature_idx) => (
                <div
                  key={feature + feature_idx}
                  style={{ marginBottom: '10px' }}
                >
                  <DebouncedTextField
                    id={`Feature ${feature_idx}`}
                    placeholder="Feature text"
                    variant="outlined"
                    size="small"
                    value={feature}
                    onChange={(newValue) =>
                      updateFeature(feature_idx, newValue)
                    }
                    style={{ width: 'calc(100%)' }}
                    InputProps={{
                      endAdornment: (
                        <IconButton
                          color="secondary"
                          onClick={() => deleteFeature(feature_idx)}
                        >
                          <DeleteIcon style={{ fontSize: 18 }} />
                        </IconButton>
                      ),
                    }}
                  />
                </div>
              ))}
              <button
                className="add-feature font s15 cfff cleanbtn"
                onClick={addFeature}
              >
                + Add feature
              </button>
            </div>
          </div>
        </div>
      </SettingDialog>
    );
  };

  const entrataApiSetting = () => {
    return (
      <SettingDialog
        title="Entrata API"
        open={openDialog}
        onClose={handleClose}
        handleClose={handleClose}
        setOpenDialog={setOpenDialog}
      >
        <SimpleAccordion title="Entrata">
          <Grid container spacing={2}>
            <Grid item md={12}>
              <EntrataIntegration form_id={form_id ? form_id : uuid} />
            </Grid>
          </Grid>
        </SimpleAccordion>
      </SettingDialog>
    );
  };

  const leadEmailSetting = () => {
    return (
      <SettingDialog2
        title="Email to Leads"
        open={openDialog}
        onClose={handleClose}
        handleClose={handleClose}
        setOpenDialog={setOpenDialog}
        customButtonText="Save"
        customButtonFunction={async () => {
          setLeadError();
          const res = await post('/integration/details', {
            magnet_uuid: form_id ? form_id : uuid,
            data: {
              'email-lead-promo': customizableEmail,
            },
          });
          if (res.error) {
            setLeadError('Unable to update integrations please try again');
            return true;
          } else {
            return false;
          }
        }}
      >
        <EmailLeads
          customizableEmail={customizableEmail}
          setCustomizableEmail={setCustomizableEmail}
          magnetSettings={magnetSettings}
          form_id={form_id}
          uuid={uuid}
        />
      </SettingDialog2>
    );
  };

  const facebookPixelSetting = () => {
    return (
      <SettingDialog
        title="Facebook Pixel"
        open={openDialog}
        onClose={handleClose}
        handleClose={handleClose}
        setOpenDialog={setOpenDialog}
        customButtonText="Save"
        customButtonFunction={async () => {
          setLeadError();
          const res = await post('/integration/details', {
            magnet_uuid: form_id ? form_id : uuid,
            data: {
              'facebook-pixel': facebookPixel,
            },
          });
          if (res.error) {
            setLeadError('Unable to update integrations please try again');
            return true;
          } else {
            return false;
          }
        }}
      >
        <DebouncedTextField
          fullWidth
          value={facebookPixel}
          onChange={setFacebookPixel}
        />
        {emailError && <Alert severity="error">{leadError}</Alert>}
      </SettingDialog>
    );
  };

  const googleAnalyticsSetting = () => {
    return (
      <SettingDialog
        title="Google Analytics"
        open={openDialog}
        onClose={handleClose}
        handleClose={handleClose}
        setOpenDialog={setOpenDialog}
        customButtonText="Save"
        customButtonFunction={async () => {
          setLeadError();
          const res = await post('/integration/details', {
            magnet_uuid: form_id ? form_id : uuid,
            data: {
              'google-analytics': googleAnalyticsVal,
            },
          });
          if (res.error) {
            setLeadError('Unable to update integrations please try again');
            return true;
          } else {
            return false;
          }
        }}
      >
        <DebouncedTextField
          fullWidth
          value={googleAnalyticsVal}
          onChange={setGoogleAnalyticsVal}
        />
        {emailError && <Alert severity="error">{leadError}</Alert>}
      </SettingDialog>
    );
  };

  const leadGoalsSetting = () => {
    return (
      <SettingDialog
        title="Lead Goals"
        open={openDialog}
        onClose={handleClose}
        handleClose={handleClose}
        setOpenDialog={setOpenDialog}
        customButtonText="Save"
        customButtonFunction={async () => {
          updateSettings();
        }}
      >
        <LeadGoalConfiguration
          routeOpts={routeOpts}
          chatbot={chatbot}
          defaultConfig={defaultEmbedFields}
          setDefaultConfig={setDefaultEmbedFields}
        />
      </SettingDialog>
    );
  };

  const plainTextEmailSetting = () => {
    const defaultPlainTextEmailTemplate = `
 
Email: {{leadEmail}}
Name: {{leadName}}
First Name: {{leadFirstName}}
Last Name: {{leadLastName}}
Phone: {{leadPhone}}
Notes: {{leadNotes}}

`;
    return (
      <SettingDialog
        title="Plain text lead email"
        open={openDialog}
        onClose={handleClose}
        handleClose={handleClose}
        setOpenDialog={setOpenDialog}
        customButtonText="Save"
        customButtonFunction={async () => {
          setEmailError();
          console.log('email-plain', plainEmail);
          const res = await post('/integration/details', {
            magnet_uuid: uuid,
            data: {
              ['email-plain']: plainEmail,
            },
          });
          if (res.error) {
            setEmailError('Unable to update integrations please try again');
            return true;
          } else {
            return false;
          }
        }}
      >
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="flex-start"
          spacing={1}
        >
          <Grid item xs={12}>
            {
              'Add emails to receive a plain text email when a lead is submitted.'
            }
            {/* <ChipInput
              variant="outlined"
              placeholder="Add emails"
              className="my-3"
              fullWidth
              blurBehavior="add"
              value={[]}
              onAdd={(chip) => {
                let temp = plainEmail?.team_email.split(',');
                temp.push(chip);
                console.log('temp: ', temp, {
                  ...plainEmail,
                  team_email: temp.join(','),
                });
                setPlainEmail({
                  ...plainEmail,
                  team_email: temp.join(','),
                });
              }}
              onDelete={(chip, index) => {
                let temp = plainEmail?.team_email.split(',');
                temp = temp.filter((val, i) => i !== index);
                setPlainEmail({
                  ...plainEmail,
                  team_email: temp.join(','),
                });
              }}
            /> */}
            <br />
            {plainEmail.team_email
              ?.split(',')
              .filter((o) => o)
              .map((email, idx) => (
                <Chip
                  color="primary"
                  style={{ marginLeft: 5, marginTop: 5 }}
                  label={email}
                  onDelete={() => {
                    let temp = plainEmail.team_email.split(',');
                    temp = temp.filter((val, i) => i !== idx);
                    setPlainEmail({
                      ...plainEmail,
                      team_email: temp.join(','),
                    });
                  }}
                />
              ))}
          </Grid>
          <Grid item xs={12}>
            <Paper component="form" className={classes.root}>
              <InputBase
                className={classes.input}
                placeholder="Email"
                inputRef={plainEmailRef}
                onChange={(e) => {
                  setCurrEmail(e.target.value);
                  // plainEmailRef.current = e.target.value;
                }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    e.preventDefault();
                    handleSubmitPlainEmail(e.target.value);
                  }
                }}
                inputProps={{ 'aria-label': 'Add email' }}
              />
              <Divider className={classes.divider} orientation="vertical" />
              <IconButton
                color="primary"
                className={classes.iconButton}
                onClick={() => {
                  handleSubmitPlainEmail();
                }}
                aria-label="directions"
              >
                <AddIcon />
              </IconButton>
            </Paper>
            {emailError && <Alert severity="error">{emailError}</Alert>}
          </Grid>
          <input
            //  className={classes.input}
            placeholder="Subject Line"
            value={plainEmail.subject}
            onChange={(e) => {
              setPlainEmail({
                ...plainEmail,
                subject: e.target.value,
              });
              // plainEmailRef.current = e.target.value;
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault();
                setPlainEmail({
                  ...plainEmail,
                  subject: e.target.value,
                });
              }
            }}
            style={{
              width: '100%',
              border: '1px solid lightblue',
              padding: '5px',
              marginTop: '5px',
              borderRadius: '6px',
            }}
          />
          {/* <Grid item xs={12}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <DateTimePicker
                fullWidth
                label="Select first occurrence"
                value={selectedDate}
                onChange={(date) => handleDateChange(date)}
              />
            </MuiPickersUtilsProvider>
          </Grid> */}
          <TextareaAutosize
            aria-label="minimum height"
            minRows={3}
            placeholder="Your email template"
            value={plainEmail?.template || defaultPlainTextEmailTemplate}
            onChange={(event) =>
              setPlainEmail({
                ...plainEmail,
                template: event.target.value,
              })
            }
            style={{
              width: '100%',
              border: '1px solid lightblue',
              padding: '5px',
              marginTop: '5px',
              borderRadius: '6px',
            }}
          />
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Switch
                  checked={plainEmail.live}
                  onChange={(event) =>
                    setPlainEmail({
                      ...plainEmail,
                      live: event.target.checked,
                    })
                  }
                  name="checkedLive"
                  inputProps={{ 'aria-label': 'live checkbox' }}
                />
              }
              label="Go Live"
            />
            <Button
              variant="outlined"
              onClick={async () => {
                console.log(
                  'plainEmail.team_email',
                  plainEmail.team_email,
                  community_id
                );
                try {
                  await axios.post(
                    `https://api.leasemagnets.com/email/send_plain_text_lead_email`,
                    {
                      uuid: uuid,
                      name: name,
                      cid: community_id,
                      cmpEmails: plainEmail.team_email,
                      // message : "Hi New customer"
                      template: plainEmail.template,
                    },

                    { auth: false }
                  );
                  toast('Email sent successfully!');
                } catch (error) {
                  console.log('error', error);
                  console.log('error', error.repsonse);
                }
              }}
            >
              Send test email
            </Button>
            <Button
              variant="outlined"
              onClick={() => {
                console.log('reset plain email template TYG');
                setPlainEmail({
                  ...plainEmail,
                  template: defaultPlainTextEmailTemplate,
                });
              }}
            >
              Reset Email Template
            </Button>
          </Grid>
        </Grid>
      </SettingDialog>
    );
  };

  const weeklyReportingEmailSetting = () => {
    return (
      <SettingDialog
        title="Weekly reporting emails"
        open={openDialog}
        onClose={handleClose}
        handleClose={handleClose}
        setOpenDialog={setOpenDialog}
        customButtonText="Save"
        customButtonFunction={async () => {
          console.log('weeklyReportingEmails', weeklyReportingEmails);
          setEmailError();
          const res = await post('/integration/details', {
            magnet_uuid: uuid,
            data: {
              ['weekly-reporting']: weeklyReportingEmails,
            },
          });
          if (res.error) {
            console.log('errrrr', res);
            setEmailError('Unable to update integrations please try again');
            return true;
          } else {
            return false;
          }
        }}
      >
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="flex-start"
          spacing={1}
        >
          <Grid item xs={12}>
            {'Enter emails for weekly reporting below.'}
            <br />
            {weeklyReportingEmails.team_email
              ?.split(',')
              .filter((o) => o)
              .map((email, idx) => (
                <Chip
                  color="primary"
                  style={{ marginLeft: 5, marginTop: 5 }}
                  label={email}
                  onDelete={() => {
                    let temp = weeklyReportingEmails.team_email.split(',');
                    temp = temp.filter((val, i) => i !== idx);
                    setWeeklyReportingEmails({
                      ...weeklyReportingEmails,
                      team_email: temp.join(','),
                    });
                  }}
                />
              ))}
          </Grid>
          <Grid item xs={12}>
            <Paper component="form" className={classes.root}>
              <InputBase
                className={classes.input}
                placeholder="Email"
                inputRef={emailRef}
                onChange={(e) => {
                  setCurrEmail(e.target.value);
                }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    e.preventDefault();
                    handleSubmitEmail(e.target.value);
                  }
                }}
                inputProps={{ 'aria-label': 'Add email' }}
              />
              <Divider className={classes.divider} orientation="vertical" />
              <IconButton
                color="primary"
                className={classes.iconButton}
                onClick={() => {
                  handleSubmitEmail();
                }}
                aria-label="directions"
              >
                <AddIcon />
              </IconButton>
            </Paper>
            {emailError && <Alert severity="error">{emailError}</Alert>}
          </Grid>
          <Grid item xs={12}>
            {/* <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <DateTimePicker
                fullWidth
                label="Select first occurrence"
                value={selectedDate}
                onChange={(date) => handleDateChange(date)}
              />
            </MuiPickersUtilsProvider> */}
            {weekdays.map((item, index) => (
              <Fab
                onClick={() => {
                  setWeeklyReportingEmails({
                    ...weeklyReportingEmails,
                    weekday: item,
                  });
                }}
                className="mr-2"
                variant="extended"
                size="small"
                color={
                  weeklyReportingEmails.weekday == item ? 'primary' : 'white'
                }
                aria-label="add"
              >
                {item}
              </Fab>
            ))}
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Switch
                  checked={weeklyReportingEmails.live}
                  onChange={(event) =>
                    setWeeklyReportingEmails({
                      ...weeklyReportingEmails,
                      live: event.target.checked,
                    })
                  }
                  name="checkedLive"
                  inputProps={{ 'aria-label': 'live checkbox' }}
                />
              }
              label="Go Live"
            />
            <Button
              onClick={async () => {
                try {
                  let res = await axios.post(
                    `https://groupreportingemail-tyg.onrender.com/sendtestmail`,
                    {
                      uuid: uuid,
                      name: name,
                      cid: community_id,
                      target: weeklyReportingEmails.team_email,
                      specificemail: true,
                    }
                  );
                  if (res?.data?.res == 'ok') {
                    toastNotification('Email sent', 'success');
                  }
                  console.log('res', res);
                } catch (error) {
                  console.log('error', error);
                }
              }}
            >
              Send test email
            </Button>
          </Grid>
        </Grid>
      </SettingDialog>
    );
  };

  async function onSelectImage(e) {
    if (e.target.files && e.target.files.length > 0) {
      setPhotoUrl(URL.createObjectURL(e.target.files[0]));
      setFile(e.target.files[0]);
      setFileName(e.target.files[0].name);
    }
  }

  const handleUpload = async () => {
    try {
      let url = '';

      if (file) {
        url = await uploadFile(
          file,
          setProgress,
          'branding',
          'images/' + community_id
        );
      }

      // setMetadata((prevState) => ({
      //   ...prevState,
      //   photoUrl: url,
      // }));

      let postData = {
        magnet_uuid: magnetUuid,
        custom_branding: {
          img: url || photoUrl || '',
          url: websiteUrl,
        },
      };

      console.log('postData', postData);
      if (url || photoUrl) {
        post('/magnets/template/updateMagnet/branding/custom', postData, {
          auth: false,
        })
          .then((response) => {
            // Ensure service worker exists, and that we really are getting a JS file.
            console.log('custom branding saved...', response);
            toastNotification('Settings updated', 'success');
          })
          .catch((error) => {
            console.log('custom branding updates failed....', error.message);
            toastNotification(error.message, 'error');
          });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const customBrandingSetting = () => {
    return (
      <SettingDialog
        title="Custom Branding"
        open={openDialog}
        onClose={handleClose}
        handleClose={handleClose}
        setOpenDialog={setOpenDialog}
        customButtonText="Save"
        customButtonFunction={async () => {
          handleUpload();
        }}
      >
        <div className="custom-branding-dialog flex flex-col">
          <div className="form">
            <div className="image-blk">
              <div className="brand-logo">
                {photoUrl && <img src={photoUrl} alt="" />}
              </div>
              <button
                className="font change-img s12 b5 color"
                onClick={() => document.getElementById('_upload-file_').click()}
              >
                Upload Image
              </button>
              <input
                onClick={(e) => (e.target.value = '')}
                onChange={onSelectImage}
                type="file"
                accept="image/*"
                className="hide-element "
                id="_upload-file_"
              />
            </div>
            <div className="rit flex flex-col">
              <div className="field">
                <input
                  type="text"
                  placeholder="custom branding logo url"
                  className="iput font"
                  defaultValue={photoUrl}
                  onChange={(e) => setPhotoUrl(e.target.value)}
                />
              </div>
              <div className="field">
                <input
                  type="text"
                  placeholder="custom branding website url"
                  className="iput font"
                  defaultValue={websiteUrl}
                  onChange={(e) => setWebsiteUrl(e.target.value)}
                />
              </div>
              {/* <div className="field">
                <button className="save-brand" onClick={handleUpload}>
                  Save Changes
                </button>
              </div> */}
            </div>
          </div>
        </div>
      </SettingDialog>
    );
  };
  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      {/* <CustomHeader
        url={url}
        name={name}
        community_id={community_id}
        title="Settings"
      /> */}
      <div className="magnet-setting-page">
        {/* <div className="page-title font s18 b6 black">
          Update your workspace settings
        </div> */}
        <div className="wrapper flex flex-col">
          {loading ? (
            <>
              {[{}, {}, {}, {}].map((item, index) => (
                <div className="section">
                  <div className="title holder" />
                  <div className="wrap">
                    <div className="holder" />
                    <div className="holder" />
                  </div>
                </div>
              ))}
            </>
          ) : (
            <>
              {/* View Settings */}
              <div className="section">
                <div className="title font black">Mini Apps</div>
                <div className="wrap">
                  <div
                    className="block flex flex-col "
                    onClick={() => {
                      setOpenDialog(true);
                      setSelectedSetting('ai_corpus');
                    }}
                  >
                    <div className="meta flex aic">
                      <img
                        src="https://pub-0330099bbb82461a86d1f9dc5cba5124.r2.dev/image 3.png"
                        className="icon"
                      />
                      <div className="lbl font black">AI FAQ Corpus</div>
                    </div>
                    <div className="text font s14">
                      Build your AI Data model for corpus and create a shareable
                      chatbot that can answer questions about your property
                    </div>
                  </div>

                  <div
                    className="block flex flex-col"
                    onClick={() => {
                      setOpenDialog(true);
                      setSelectedSetting('photo_gallery');
                    }}
                  >
                    <div className="meta flex aic">
                      <img
                        src="https://pub-0330099bbb82461a86d1f9dc5cba5124.r2.dev/ic_round-add-to-photos.png"
                        className="icon"
                      />
                      <div className="lbl font black">Photo Gallery</div>
                    </div>
                    <div className="text font s14">
                      Create an intelligent & labeled Photo Gallery, that can we
                      can index and share in other elements of the Tour
                    </div>
                  </div>
                </div>
              </div>
              {/* View Settings */}
              <div className="section">
                <div className="title font black">Custom Variables</div>
                <div className="wrap">
                  <div
                    className="block flex flex-col"
                    onClick={() => {
                      setOpenDialog(true);
                      setSelectedSetting('share_page-setting');
                    }}
                  >
                    <div className="meta flex aic">
                      <img
                        src={require('../../assets/setting/share.png').default}
                        className="icon"
                      />
                      <div className="lbl font black">Share page settings</div>
                    </div>
                    <div className="text font s14">
                      Make sure your lead data gets sent to the Entrata
                      Dashboard account
                    </div>
                  </div>
                  {/* <div
                    className="block flex flex-col"
                    onClick={() => {
                      setOpenDialog(true);
                      setSelectedSetting('tour_default');
                    }}
                  >
                    <div className="meta flex aic">
                      <img
                        src={
                          require('../../assets/setting/override-tour.png')
                            .default
                        }
                        className="icon"
                      />
                      <div className="lbl font black">
                        Override Tour Defaults
                      </div>
                    </div>
                    <div className="text font s14">
                      Make sure your lead data gets sent to the Entrata
                      Dashboard account
                    </div>
                  </div> */}
                  {/* <div
                    className="block flex flex-col"
                    onClick={() => {
                      setOpenDialog(true);
                      setSelectedSetting('dynamic_variables');
                    }}
                  >
                    <div className="meta flex aic">
                      <img
                        src={
                          require('../../assets/setting/dynamic-variables.png')
                            .default
                        }
                        className="icon"
                      />
                      <div className="lbl font black">Dynamic Variables</div>
                    </div>
                    <div className="text font s14">
                      Make sure your lead data gets sent to the Entrata
                      Dashboard account
                    </div>
                  </div> */}
                  {/* <div
                    className="block flex flex-col"
                    onClick={() => {
                      setOpenDialog(true);
                      setSelectedSetting('chatbot-setting');
                    }}
                  >
                    <div className="meta flex aic">
                      <div className="icon ico icon-user-bubble s20" />
                      <div className="lbl font black">
                        Override Chatbot Defaults
                      </div>
                    </div>
                    <div className="text font s14">
                      Make sure your lead data gets sent to the Entrata
                      Dashboard account
                    </div>
                  </div> */}
                  {/* <div
                    className="block flex flex-col"
                    onClick={() => {
                      setOpenDialog(true);
                      setSelectedSetting('special-offer');
                    }}
                  >
                    <div className="meta flex aic">
                      <div className="icon ico icon-rocket s20" />
                      <div className="lbl font black">
                        Update your special offer
                      </div>
                    </div>
                    <div className="text font s14">
                      Use this variable to update your special offers throughout
                      your tour, chatbot, and email communication
                    </div>
                  </div> */}
                  <div
                    className="block flex flex-col"
                    onClick={() => {
                      setOpenDialog(true);
                      setSelectedSetting('custom-branding');
                    }}
                  >
                    <div className="meta flex aic">
                      <div className="icon ico icon-rocket s20" />
                      <div className="lbl font black">Custom Branding</div>
                    </div>
                    <div className="text font s14">
                      Use this variable to update your special offers throughout
                      your tour, chatbot, and email communication
                    </div>
                  </div>
                </div>
              </div>

              {/* Event Tracking Settings */}
              <div className="section">
                <div className="title font black">Event Tracking Settings</div>
                <div className="wrap flex aic">
                  <div
                    className="block flex flex-col"
                    onClick={() => {
                      setOpenDialog(true);
                      setSelectedSetting('entrata_api');
                    }}
                  >
                    <div className="meta flex aic">
                      <img
                        src={
                          require('../../assets/setting/event-tracking.png')
                            .default
                        }
                        className="icon entrata"
                      />
                      <div className="lbl font black">Entrata API</div>
                    </div>
                    <div className="text font s14">
                      Make sure your lead data gets sent to the Entrata
                      Dashboard account
                    </div>
                  </div>
                  <div
                    className="block flex flex-col"
                    onClick={() => {
                      setOpenDialog(true);
                      setSelectedSetting('facebook_pixel');
                    }}
                  >
                    <div className="meta flex aic">
                      <img
                        src={
                          require('../../assets/setting/facebook.png').default
                        }
                        className="icon"
                      />
                      <div className="lbl font black">
                        Facebook Pixel Events API
                      </div>
                    </div>
                    <div className="text font s14">
                      Make sure your lead data gets sent to the Entrata
                      Dashboard account
                    </div>
                  </div>
                  <div
                    className="block flex flex-col"
                    onClick={() => {
                      setOpenDialog(true);
                      setSelectedSetting('google_analytics');
                    }}
                  >
                    <div className="meta flex aic">
                      <img
                        src={
                          require('../../assets/setting/analytics.png').default
                        }
                        className="icon"
                      />
                      <div className="lbl font black">
                        Google Analytics Events API
                      </div>
                    </div>
                    <div className="text font s14">
                      Make sure your lead data gets sent to the Entrata
                      Dashboard account
                    </div>
                  </div>
                  <div
                    className="block flex flex-col"
                    onClick={() => {
                      setOpenDialog(true);
                      setSelectedSetting('lead_goals');
                    }}
                  >
                    <div className="meta flex aic">
                      <FlagIcon className="icon" />
                      <div className="lbl font black">Lead Goals</div>
                    </div>
                    <div className="text font s14">
                      Determine thresholds and notifications for hot leads
                    </div>
                  </div>
                </div>
              </div>

              {/* Lead Notification Settings */}
              <div className="section">
                <div className="title font black">
                  Team and Lead Notification Settings
                </div>
                <div className="wrap flex aic">
                  <div
                    className="block flex flex-col"
                    onClick={() => {
                      setOpenDialog(true);
                      setSelectedSetting('lead_email');
                    }}
                  >
                    <div className="meta flex aic">
                      <img
                        src={require('../../assets/setting/email.png').default}
                        className="icon email"
                      />
                      <div className="lbl font black">Lead Email</div>
                    </div>
                    <div className="text font s14">
                      Make sure your lead data gets sent to the Entrata
                      Dashboard account
                    </div>
                  </div>
                  <div
                    className="block flex flex-col"
                    onClick={() => {
                      setOpenDialog(true);
                      setSelectedSetting('plain_text_email');
                    }}
                  >
                    <div className="meta flex aic">
                      <img
                        src={require('../../assets/setting/email.png').default}
                        className="icon email"
                      />
                      <div className="lbl font black">Plain text email</div>
                    </div>
                    <div className="text font s14">
                      Make sure your lead data gets sent to the Entrata
                      Dashboard account
                    </div>
                  </div>
                </div>

                <div className="section"></div>
                <div className="wrap flex aic">
                  <div
                    className="block flex flex-col"
                    onClick={() => {
                      setOpenDialog(true);
                      setSelectedSetting('community_managers');
                    }}
                  >
                    <div className="meta flex aic">
                      <img
                        src={require('../../assets/ava.png').default}
                        className="icon email"
                      />
                      <div className="lbl font black">Manage your Team</div>
                    </div>
                    <div className="text font s14">
                      Add or manage team members who have access to edit,
                      update, or view the tour
                    </div>
                  </div>
                </div>
              </div>

              {/* Reporting Settings */}
              <div className="section">
                <div className="title font black">Reporting</div>
                <div className="wrap flex aic">
                  <div
                    className="block flex flex-col"
                    onClick={() => {
                      setOpenDialog(true);
                      setSelectedSetting('weekly-reporting_email');
                    }}
                  >
                    <div className="meta flex aic">
                      <img
                        src={require('../../assets/setting/email.png').default}
                        className="icon email"
                      />
                      <div className="lbl font black">
                        Weekly Reporting email
                      </div>
                    </div>
                    <div className="text font s14">
                      Make sure your lead data gets sent to the Entrata
                      Dashboard account
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      {selectedSetting == 'tour_default'
        ? defaultTourSetting()
        : selectedSetting == 'entrata_api'
        ? entrataApiSetting()
        : selectedSetting == 'dynamic_variables'
        ? dynamicVariablesSetting()
        : selectedSetting == 'chatbot-setting'
        ? chatbotSetting()
        : selectedSetting == 'share_page-setting'
        ? sharePageSettings()
        : selectedSetting == 'special-offer'
        ? SpecialOffer()
        : selectedSetting == 'lead_email'
        ? leadEmailSetting()
        : selectedSetting == 'community_managers'
        ? communitymanagers()
        : selectedSetting == 'facebook_pixel'
        ? facebookPixelSetting()
        : selectedSetting == 'google_analytics'
        ? googleAnalyticsSetting()
        : selectedSetting == 'lead_goals'
        ? leadGoalsSetting()
        : selectedSetting == 'plain_text_email'
        ? plainTextEmailSetting()
        : selectedSetting == 'weekly-reporting_email'
        ? weeklyReportingEmailSetting()
        : selectedSetting == 'custom-branding'
        ? customBrandingSetting()
        : selectedSetting == 'ai_corpus'
        ? aiCorpus()
        : selectedSetting == 'photo_gallery'
        ? photoGallery()
        : null}
    </>
  );
};

export default MagnetSettings;

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const SettingDialog = ({
  children,
  title = 'summary',
  customButtonText,
  customButtonFunction,
  setOpenDialog,
  handleClose,
  maxWidth = 'sm',
  ...rest
}) => {
  return (
    <>
      <Dialog
        TransitionComponent={Transition}
        keepMounted
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        fullWidth
        maxWidth={maxWidth}
        {...rest}
        BackdropProps={{
          style: { backgroundColor: 'rgba(17, 24, 39, 0.9)' },
        }}
      >
        <div className=" w-full flex items-center justify-between pr-4">
          <DialogTitle id="alert-dialog-slide-title">{title} </DialogTitle>
          <IoMdClose
            onClick={handleClose}
            className=" w-8 h-8 cursor-pointer text-gray-600"
          />
        </div>
        <DialogContent>{children}</DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Back
          </Button>
          {customButtonText && (
            <Button
              variant="contained"
              onClick={async () => {
                const res = await customButtonFunction();
                setOpenDialog(res);
              }}
              color="primary"
            >
              {customButtonText}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};
const SettingDialog2 = ({
  children,
  title = 'summary',
  customButtonText,
  customButtonFunction,
  setOpenDialog,
  handleClose,
  maxWidth = 'lg',
  ...rest
}) => {
  return (
    <>
      <Dialog
        TransitionComponent={Transition}
        keepMounted
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        fullWidth
        maxWidth={maxWidth}
        {...rest}
        BackdropProps={{
          style: { backgroundColor: 'rgba(17, 24, 39, 0.9)' },
        }}
      >
        <div className=" w-full flex items-center justify-between pr-4">
          <DialogTitle id="alert-dialog-slide-title">{title} </DialogTitle>
          <IoMdClose
            onClick={handleClose}
            className=" w-8 h-8 cursor-pointer text-gray-600"
          />
        </div>
        <DialogContent>{children}</DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Back
          </Button>
          {customButtonText && (
            <Button
              variant="contained"
              onClick={async () => {
                const res = await customButtonFunction();
                setOpenDialog(res);
              }}
              color="primary"
            >
              {customButtonText}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};
