import React, { useEffect, useState } from 'react';
import { AiOutlineArrowRight } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import avatar from '../../assets/testimonials.jpeg';
import {
  AddDeleteCommunities as AddCommunities,
  BillingSignup,
  PersonalInfoSignup,
} from '../../components/SignUpForm';
import Header from '../../layout/Header';
import { getUserCommunities } from '../../utils/routes';
import '../signin/signin.css';
const LeasemagnetsLogo = require('../../assets/tour-video-NEW-TYG-logo.png');

export const SignUp = (props) => {
  const [signupStep, setSignupStep] = useState(1);

  const [communities, setCommunities] = useState([]);
  const [hasCommunities, setHasCommunities] = useState(false);

  const loadCommunities = async () => {
    const res = await getUserCommunities();
    if (!res.error && res.communities.length) {
      setHasCommunities(true);
    }
  };

  useEffect(() => {
    if (props.signupStep) {
      console.log('signup step was: ', signupStep);
      setSignupStep(props.signupStep);
      console.log('signup step is now: ', signupStep);
      loadCommunities();
    }
  }, []);

  return (
    <>
      <Header />
      <div className="SignUp">
        <div
          className=" grid grid-cols-1 lg:grid-cols-2 gap-5 items-center h-full mt-44"
          style={{ width: '70vw' }}
          // classes={{ root: 'center_container' }}
        >
          <div className="flex items-center justify-center flex-col">
            <h6 className=" pt-20 w-full text-center text-lg font-medium text-gray-800">
              Customers Stories
            </h6>
            <h1 className=" text-4xl font-bold text-gray-800 w-full text-center pt-4">
              What our members are saying
            </h1>
            <div className=" bg-white rounded-md border mt-4 p-3">
              <div className=" flex items-center gap-2">
                <img
                  src={avatar}
                  className=" w-11 h-11 rounded-full"
                  style={{ objectFit: 'cover' }}
                  alt=""
                />
                <div>
                  <p className=" text-lg font-bold text-gray-800">
                    Gustavo Workman
                  </p>
                  <p className=" text-sm  text-gray-600">
                    Kierra Morris Property Manager, Bankier Apartments
                  </p>
                </div>
              </div>
              <div className="pt-4">
                <h6 className=" font-semibold text-gray-800">
                  “...Gave me a true virtual leasing advantage - best marketing
                  decision have ever made & the free trial made it a no
                  brainer.”
                </h6>

                <div className=" flex items-center justify-end w-full pt-2">
                  <Link to="/">
                    <AiOutlineArrowRight className=" text-pr w-7 h-7" />
                  </Link>
                </div>
              </div>
            </div>
          </div>

          <div
            className=" flex flex-col justify-center"
            style={{ margin: '50px 0', paddingLeft: '20px' }}
          >
            {signupStep === 1 && (
              <PersonalInfoSignup
                setSignupStep={setSignupStep}
                communities={communities}
                setCommunities={setCommunities}
              />
            )}
            {signupStep === 2 && (
              <AddCommunities
                setSignupStep={setSignupStep}
                communities={communities}
                setCommunities={setCommunities}
                hasCommunities={hasCommunities}
              />
            )}
            {signupStep === 3 && (
              <BillingSignup
                setSignupStep={setSignupStep}
                communities={communities}
                setCommunities={setCommunities}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};
